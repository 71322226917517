require("@fontsource/lora/500-italic.css");
require("@fontsource/lora/700-italic.css");
require("@fontsource/poppins/400.css");
require("@fontsource/poppins/600.css");
require("@fontsource/poppins/700.css");

const smoothscroll = require("smoothscroll-polyfill");

require("./src/styles/global.scss");

/* eslint-disable no-empty, no-underscore-dangle */

exports.onClientEntry = () => {
  smoothscroll.polyfill();

  if (!document.body.classList.contains("body")) {
    document.body.classList.add("body");
  }

  let preferredTheme;

  try {
    preferredTheme = localStorage.getItem("theme");
  } catch (error) {}

  window.__themeEventTarget = new EventTarget();

  function setTheme(newTheme) {
    if (preferredTheme && document.body.classList.contains(preferredTheme)) {
      document.body.classList.replace(preferredTheme, newTheme);
    } else {
      document.body.classList.add(newTheme);
    }
    window.__theme = newTheme;
    preferredTheme = newTheme;
    window.__themeEventTarget.dispatchEvent(new Event("change"));
  }

  window.__setPreferredTheme = function __setPreferredTheme(newTheme) {
    setTheme(newTheme);
    try {
      localStorage.setItem("theme", newTheme);
    } catch (error) {}
  };

  const darkQuery = window.matchMedia("(prefers-color-scheme: dark)");
  darkQuery.addEventListener("change", (event) => {
    window.__setPreferredTheme(event.matches ? "dark" : "light");
  });

  setTheme(preferredTheme || (darkQuery.matches ? "dark" : "light"));
};

/* eslint-enable no-empty, no-underscore-dangle */
