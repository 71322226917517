module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/justine-severino/justine-severino/node_modules/gatsby-remark-images","id":"3a50ef94-671b-53c0-80f4-d16fa295db09","name":"gatsby-remark-images","version":"6.10.2","modulePath":"/home/runner/work/justine-severino/justine-severino/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1320,"quality":95,"withWebp":true,"withAvif":true,"linkImagesToOriginal":false,"backgroundColor":"transparent"},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/justine-severino/justine-severino","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1320,"quality":95,"withWebp":true,"withAvif":true,"linkImagesToOriginal":false,"backgroundColor":"transparent"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Justine Severino","short_name":"Justine","start_url":"/","background_color":"#f9f7ef","theme_color":"#f9f7ef","display":"minimal-ui","icon":"src/media/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ab5a121000f733ea783a5ef7259a9e89"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/contact/","/project/*"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
