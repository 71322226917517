import * as React from "react";
import { Link } from "gatsby";

import * as styles from "./Button.module.scss";

interface BaseProps {
  size?: "large" | "normal";
  variant: "link" | "outline-primary" | "primary";
}

interface AnchorProps extends BaseProps, React.ComponentProps<"a"> {}

interface ButtonProps extends BaseProps, React.ComponentProps<"button"> {
  type?: "button" | "reset" | "submit";
}

interface LinkProps
  extends BaseProps,
    React.PropsWithoutRef<React.ComponentProps<typeof Link>> {}

function hasHref(
  props: AnchorProps | ButtonProps | LinkProps,
): props is AnchorProps {
  return "href" in props && props.href !== undefined;
}

function hasTo(
  props: AnchorProps | ButtonProps | LinkProps,
): props is LinkProps {
  return "to" in props && props.to !== undefined;
}

function hasType(
  props: AnchorProps | ButtonProps | LinkProps,
): props is ButtonProps {
  return "type" in props && props.type !== undefined;
}

function Button(props: ButtonProps | LinkProps): JSX.Element {
  const { variant, className, size } = props;
  const buttonClassName = new Set([
    styles.btn,
    styles[
      variant
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (_, character: string) =>
          character.toUpperCase(),
        )
    ],
    className,
  ]);
  if (size === "large") {
    buttonClassName.add(styles.large);
  }
  if (hasHref(props)) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a {...props} className={Array.from(buttonClassName).join(" ")} />;
  }
  if (hasTo(props)) {
    return (
      <Link {...props} className={Array.from(buttonClassName).join(" ")} />
    );
  }
  if (hasType(props)) {
    return (
      // eslint-disable-next-line react/button-has-type
      <button {...props} className={Array.from(buttonClassName).join(" ")} />
    );
  }
  throw new Error('Button must have either the "to" prop or the "type" prop');
}

Button.defaultProps = {
  size: "normal",
};

export default Button;
