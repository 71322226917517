import * as React from "react";

import * as styles from "./ProjectSection.module.scss";

function ProjectSection({
  children,
  ...props
}: React.ComponentProps<"section">): JSX.Element {
  return (
    <section {...props} className={styles.section}>
      <div className={styles.container}>{children}</div>
    </section>
  );
}

export default ProjectSection;
