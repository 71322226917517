import * as React from "react";

import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./ProjectFooter.module.scss";
import Button from "../Button/Button";

interface ProjectFooterProps extends React.ComponentProps<"div"> {
  background: string;
  buttonBackgroundColor: string;
  buttonColor: string;
  children?: React.ReactNode;
  color: string;
  createdOn: Array<
    | "afterEffects"
    | "audition"
    | "figma"
    | "illustrator"
    | "inDesign"
    | "photoshop"
    | "premiere"
    | "xd"
  >;
  title?: string;
  titleColor: string;
}

function ProjectFooter({
  background,
  buttonBackgroundColor,
  buttonColor,
  children,
  color,
  createdOn,
  title,
  titleColor,
  ...props
}: ProjectFooterProps): JSX.Element {
  const icons = {
    afterEffects: (
      <StaticImage
        alt="After Effects"
        className={styles.createdOnIcon}
        formats={["auto", "webp", "avif"]}
        placeholder="blurred"
        quality={95}
        src="./after-effects.png"
        width={200}
      />
    ),
    audition: (
      <StaticImage
        alt="Audition"
        className={styles.createdOnIcon}
        formats={["auto", "webp", "avif"]}
        placeholder="blurred"
        quality={95}
        src="./audition.png"
        width={200}
      />
    ),
    figma: (
      <StaticImage
        alt="Figma"
        className={styles.createdOnIcon}
        formats={["auto", "webp", "avif"]}
        placeholder="blurred"
        quality={95}
        src="./figma.png"
        width={200}
      />
    ),
    illustrator: (
      <StaticImage
        alt="Illustrator"
        className={styles.createdOnIcon}
        formats={["auto", "webp", "avif"]}
        placeholder="blurred"
        quality={95}
        src="./illustrator.png"
        width={200}
      />
    ),
    inDesign: (
      <StaticImage
        alt="InDesign"
        className={styles.createdOnIcon}
        formats={["auto", "webp", "avif"]}
        placeholder="blurred"
        quality={95}
        src="./indesign.png"
        width={200}
      />
    ),
    photoshop: (
      <StaticImage
        alt="Photoshop"
        className={styles.createdOnIcon}
        formats={["auto", "webp", "avif"]}
        placeholder="blurred"
        quality={95}
        src="./photoshop.png"
        width={200}
      />
    ),
    premiere: (
      <StaticImage
        alt="Premiere"
        className={styles.createdOnIcon}
        formats={["auto", "webp", "avif"]}
        placeholder="blurred"
        quality={95}
        src="./premiere.png"
        width={200}
      />
    ),
    xd: (
      <StaticImage
        alt="XD"
        className={styles.createdOnIcon}
        formats={["auto", "webp", "avif"]}
        placeholder="blurred"
        quality={95}
        src="./xd.png"
        width={200}
      />
    ),
  };
  return (
    <div
      {...props}
      className={[props.className, styles.footer].filter(Boolean).join(" ")}
      style={{
        background,
        ...props.style,
      }}
    >
      <div className={styles.container}>
        <h3
          className={styles.title}
          data-breeze="slide-up"
          style={{ color: titleColor }}
        >
          {title}
        </h3>
        <div
          className={styles.contactMe}
          data-breeze="slide-up"
          data-breeze-delay="2"
          style={{ color }}
        >
          {children}
        </div>
        <Button
          data-breeze="slide-up"
          data-breeze-delay="3"
          size="large"
          style={{
            backgroundColor: buttonBackgroundColor,
            borderColor: buttonBackgroundColor,
            color: buttonColor,
          }}
          to="/contact/"
          variant="primary"
        >
          get in touch
        </Button>
      </div>
      <div
        className={styles.createdOn}
        data-breeze="slide-up"
        style={{ borderColor: color }}
      >
        <h4
          className={styles.createdOnTitle}
          data-breeze="slide-up"
          style={{ color }}
        >
          Designed and Created on
        </h4>
        <div className={styles.createdOnIcons}>
          {createdOn.map((key) => {
            return (
              <div key={key} data-breeze="slide-up" data-breeze-delay="2">
                {icons[key]}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

ProjectFooter.defaultProps = {
  children: (
    <p>
      Don’t hesitate to drop me a message.
      <br />
      I’d be happy to discuss.
    </p>
  ),
  title: "Questions?",
};

export default ProjectFooter;
